import Marco from '../components/marco'
import {css} from '@emotion/core'
import Container from '@material-ui/core/Container'
import Minipagina from '../components/minipagina'
import Minipaginas from '../components/minipaginas'
import Titular from '../components/titular'
import EspaciadorVertical from '../components/espaciador-vertical'
import React from 'react'
import FormularioDeContacto from '../components/formulario-de-contacto'

const feliz = css`
  margin: 0 0.5rem
`

// #region Asesoramiento
export default function Asesoramiento() {
  const handleClose = () => {}
  // #region Render
  return (
    <Marco>
      <Container>
        <EspaciadorVertical altura={8} />
        <Minipaginas>
          <Minipagina palida>
            <EspaciadorVertical altura={36} />
            <Titular texto="Contáctame" color="primary" />
            <FormularioDeContacto contactame handleClose={handleClose}/>
            <EspaciadorVertical altura={24} />
          </Minipagina>
        </Minipaginas>
        <EspaciadorVertical altura={48} />
      </Container>
    </Marco >
  )
  // #endregion
}
// #endregion
